import axios from "axios";
let api = 'https://admin.njhjzy.com/'
let url='https://admin.njhjzy.com'
const service = axios.create({
    // 公共接口
    baseURL: process.env.NODE_ENV === "production" ? api : api,
    // 超时时间
    timeout: 10 * 1000,
})


export { service, api,url }