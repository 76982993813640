import {service} from './intercept'

/**
 * 获取首页发展历程
 */
export const courseIndex=()=>{
    return service.get('/api/course/index').then(res=>res.data)
} 
/**
 * 首页架构信息
 */
 export const frameworkIndex=()=>{
    return service.get('/api/framework/index').then(res=>res.data)
} 
/**
 * 首页战略合作
 */
 export const cooperationIndex=()=>{
    return service.get('/api/cooperation/index').then(res=>res.data)
} 
/**
 * 获取医生信
 */
 export const doctorIndex=()=>{
    return service.get('/api/doctor/index/').then(res=>res.data)
} 
/**
 * 获取信息中心列表详情
 */
 export const informationRead=(params)=>{
    return service.get('/api/information/read',{params}).then(res=>res.data)
} 
/**
 * 获取信息中心系列内容列表
 */
 export const informationIndex=(params)=>{
    return service.get('/api/information/index',{params}).then(res=>res.data)
} 
/**
 * 捐赠通道新增
 */
 export const messageAdd=(params)=>{
    return service.get('/api/message/add',{params}).then(res=>res.data)
} 
/**
 * 公益活动信息
 */
 export const activityIndex=()=>{
    return service.get('/api/activity/index').then(res=>res.data)
} 
/**
 * 学堂内容
 */
 export const schoolIndex=()=>{
    return service.get('/api/school/index').then(res=>res.data)
} 
/**
 * 研发中心系列信息
 */
 export const researchIndex=()=>{
    return service.get('/api/research/index').then(res=>res.data)
} 
/**
 * 获取系统配置信息
 */
 export const configIndex=()=>{
    return service.get('/api/config/index').then(res=>res.data)
} 