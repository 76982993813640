import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component:() => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/RD',
    name: 'RD',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Center" */ '../views/RD.vue')
  },
  {
    path: '/Information',
    name: 'Information',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Informationr" */ '../views/Information/Information.vue'),
    meta:{
      keepAlive:true
    }
    
  },
  {
    path: '/InformationContent',
    name: 'InformationContent',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "InformationContent" */ '../views/Information/InformationContent.vue')

  },
  {
    path: '/HejinSchool',
    name: 'HejinSchool',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "HejinSchool" */ '../views/HejinSchool.vue')
  },
  {
    path: '/HejinWelfare',
    name: 'HejinWelfare',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "HejinWelfare" */ '../views/HejinWelfare.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to,from,next)=>{
  if (to.path === "/informationContent") {
    from.meta.keepAlive = true;
  } else {
    from.meta.keepAlive = false;
  }
  next();
})
export default router
