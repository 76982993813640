import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isphone:false,
    config:{}
  },
  mutations: {
    setisphone(state,e){
      this.state.isphone=e
    }
  },
  actions: {
    setconfig(state,e){
      this.state.config=e
    }
  },
  modules: {
  }
})
