<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta?.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta?.keepAlive" />
  </div>
</template>


<script>
import { configIndex } from './uilt/api.js'
export default {
  created() {
    this.info()
  },
  methods: {
    info() {
      return configIndex().then(res => {
        this.$store.dispatch('setconfig', res)
      })
    },
    /** 判断是否为手机 */
    isphone(){
        window.fullWidth = document.documentElement.clientWidth;
        if (window.fullWidth < 769) {
          this.$store.commit('setisphone', true)
        } else {
          this.$store.commit('setisphone', false)
        }
    }
  },
  mounted() {
    //获取宽度判断手机还pc
    this.isphone()
    window.addEventListener('resize', ()=> {
      console.log('213')
      return setTimeout(()=>{
        this.$nextTick(()=>{
          this.isphone();
        })
      },500) 
    }, false);

  }
}
</script>

<style lang="scss">
@media screen and (max-width:768px){
  #app{
    width: 750px;
    overflow: hidden;
  }
}
@font-face {
  font-family: "FZLiShu-S01Ss";
  src: url("./assets/font/FZLiShu-S01S.ttf");
  font-stretch: "normal";
  font-weight: 400;
}


body {
  margin: 0;
  padding: 0;


}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #FEFAE9;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
}
</style>
